<template>
    <div>
        <div class="manage-box">
            <div class="manage-left" v-loading="leftLoading">
                <div class="padding-sm" style="overflow:hidden;">
                    <el-button class="fl-r" type="primary" size="mini" @click="setClass">新增关卡</el-button>
                </div>
                <div>
                    <el-table :data="classList" style="width: 100%">
                        <el-table-column prop="id" label="id">
                        </el-table-column>
                        <el-table-column prop="name" label="关卡名称">
                        </el-table-column>
                        <el-table-column prop="question_bank_name" label="题库名称">
                        </el-table-column>
                        <el-table-column prop="sort" label="排序">
                        </el-table-column>
                        <el-table-column prop="created_at" label="创建时间">
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-link type="primary" :underline="false" @click.stop="handleEdit(scope.row)">编辑
                                </el-link> -
                                <el-popconfirm icon="el-icon-info" icon-color="red" title="删除后将不可恢复，确定删除吗？"
                                    @confirm="handleDelete(scope.row)">
                                    <el-link slot="reference" type="primary" @click.stop>删除</el-link>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="table-batch margin-l-sm">
                    <span class="fl-l">
                        <el-pagination small background :current-page="page" :page-sizes="[10, 20]"
                            :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
                            @size-change="setPageSize" @current-change="setPage" :total="total">
                        </el-pagination>
                    </span>
                    <div style="clear: both"></div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {

    data() {
        return {
            leftLoading: false,
            classList: [],
            page: 1,
            pageSize: 10,
            total: 0,
            id: 0,
        }
    },
    methods: {
        ...mapActions('game', ['gameListPass', 'gameDelPass']),
        setPageSize(pageSize) {
            this.pageSize = pageSize
            this.initData()
        },
        setPage(page) {
            this.page = page
            this.initData()
        },
        handleEdit(row) {
            this.$router.push('/game/editClass/' + row.id + '/' + this.id)
        },
        async handleDelete(row) {
            let id = row.id
            const { data, res_info } = await this.gameDelPass(id)
            this.initData()
            if (res_info != 'ok') return
            this.$message.success('删除成功！')
        },
        setClass() {
            this.$router.push('/game/addClass/' + this.id)
        },
        async initData() {
            this.leftLoading = true
            let form = {
                games_id: this.id,
                page: this.page,
                pageSize: this.pageSize,
            }
            const { data } = await this.gameListPass(form)
            this.classList = data.list
            this.total = data.total
            this.leftLoading = false
        },
    },
    mounted() {
        this.id = this.$route.params.id
        this.initData()
    },
}
</script>

<style scoped>
.table-header {
    padding: 15px;
    background: #fafafa;
}

.font-size-12 {
    font-size: 12px;
}

.circle {
    border: 1px solid #ccc;
    padding: 3px;
    border-radius: 50%;
    margin-right: 3px;
    font-size: 8px;
}

.section {
    font-size: 14px;
    padding: 15px;
    display: flex;
    align-items: center;
}

.border-s {
    border-bottom: 1px solid #ebebeb
}

.w10 {
    width: 20%;
}

.w26 {
    width: 20%;
}

.manage-left {
    float: left;
    width: 100%;
    height: 100%;
}

.manage-right {
    border-left: 1px solid #ccc;
    float: right;
    width: 29%;
    height: 700px;
}

.plan-box {
    height: 600px;
    overflow: auto;
    padding: 10px
}

.plan-item {
    padding: 10px 5px;
    cursor: pointer;
}

.plan-item:hover {
    background: #EBEEF5;
}

.current {
    background: #F2F6FC;
}

.item-name {
    line-height: 24px;
}

.el-tree-node__content {
    height: auto;
    padding: 5px 0;
}
</style>